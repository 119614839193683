import React, { Component } from "react";
import Header from "../../components/header/Header";
import Projects from "../../containers/projects/Projects";
import "./Projects.css";

class ProjectsPage extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <Projects theme={theme}/>
      </div>)
        {/* <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div> */}
            {/* <div className="projects-heading-div"> */}
              {/* <div className="projects-heading-img-div">
                <img
											src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/>
                <ProjectsImg theme={theme} />
              </div> */}
              {/* <Fade right duration={2000}>
                <div className="projects-main-div">
                    {projects.data.map((project) => {
                      return (
                        <>
                          <ProjectsImg theme={theme} />
                          <div>
                            <p
                            className="subTitle skills-text"
                            style={{ color: theme.secondaryText }}
                          >
                            {project.title}
                          </p>
                          </div>
                       
                        </>
                      );
                    })}
                  </div>
                </Fade> */}
              {/* <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p>
              </div> */}
            {/* </div>
          </Fade>
        </div> */}
        {/* <div className="repo-cards-div-main">
          {ProjectsData.data.map((repo) => {
            // return <GithubRepoCard repo={repo} theme={theme} />;
          })}
        </div> */}
        {/* <Button
          text={"More Projects"}
          className="project-button"
          href={greeting.githubProfile}
          newTab={true}
          theme={theme}
        /> */}

        {/* Publications  */}
        {/* {publications.data.length > 0 ? (
          <div className="basic-projects">
            <Fade bottom duration={2000} distance="40px">
              <div className="publications-heading-div">
                <div className="publications-heading-text-div">
                  <h1
                    className="publications-heading-text"
                    style={{ color: theme.text }}
                  >
                    {publicationsHeader.title}
                  </h1>
                  <p
                    className="projects-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {publicationsHeader["description"]}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        ) : null} */}

        {/* <div className="repo-cards-div-main">
          {publications.data.map((pub) => {
            return <PublicationCard pub={pub} theme={theme} />;
          })}
        </div> */}

        {/* <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} /> */}
      {/* </div>
    ); */}
  }
}

export default ProjectsPage;
