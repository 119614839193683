import React, { Component } from "react";
import "./FullWidthImage.css";
import { Fade, Flip } from "react-reveal";

class FullWidthImage extends Component {
  render() {
    const src = this.props.src;
    const alt = this.props.alt;
    return (
      <div>
        <Fade>
          <div className="full-width-image-div">
            <img className="full-width-image-img" src={src} alt={alt} />
          </div>
        </Fade>
      </div>
    );
  }
}

export default FullWidthImage;
