import React from "react";
import "./Greeting.css";
import GreetingSection from "./GreetingSection";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";

export default function Greeting(props) {
  const theme = props.theme;
  const backgroundColor = props.backgroundColor;
  const textColor = props.textColor;

  return (
    <Fade bottom duration={0} distance="40px">
      <div style={{ backgroundColor: theme.greetingBackgroundColor }}>
        <div className="greet-main" id="greeting">
          <GreetingSection
            theme={theme}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />

          {/* <div className="greeting-main">
            <div className="greeting-text-div">
              <div>
                <h1
                  className="greeting-text"
                  style={{ color: theme.text, fontSize: theme.titleFontSize }}
                >
                  {greeting.title}
                </h1>
                {greeting.nickname && (
                  <h2
                    className="greeting-nickname"
                    style={{ color: theme.text }}
                  >
                    ( {greeting.nickname} )
                  </h2>
                )}
                <p
                  className="greeting-text-p subTitle"
                  style={{
                    color: theme.text,
                    fontSize: theme.subTitleFontSize,
                  }}
                >
                  {greeting.subTitle}
                </p> */}
          {/* <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                <Button text="See my resume" newTab={true} href={greeting.resumeLink} />
              </div> */}
          {/* </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </Fade>
  );
}
